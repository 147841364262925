import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, LinkBox, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Ласкаво просимо до Creatix Agency
			</title>
			<meta name={"description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:title"} content={"Ласкаво просимо до Creatix Agency"} />
			<meta property={"og:description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
		</Helmet>
		<Components.Header123>
			<Override slot="link2" display="none" />
		</Components.Header123>
		<Section padding="90px 0 50px 0" background="--color-dark" quarkly-title="Footer-13" sm-padding="50px 0 50px 0">
			<Box
				min-width="100px"
				min-height="100px"
				padding="50px 30px 50px 30px"
				background="linear-gradient(180deg,--color-dark 0.5%,rgba(14, 19, 23, 0.37) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66583b441d712a00233449af/images/2-4.jpg?v=2024-05-30T11:03:45.341Z) top/cover repeat scroll border-box"
				display="flex"
				justify-content="space-around"
				align-items="center"
				margin="0px 0px 80px 0px"
				md-flex-direction="column"
				md-align-items="stretch"
				md-margin="0px 0px 50px 0px"
				md-padding="30px 30px 30px 30px"
				sm-padding="20px 15px 20px 15px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					md-margin="0px 0px 30px 0px"
					md-width="100%"
					sm-margin="0px 0px 18px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline3"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Зробіть крок до трансформаційного маркетингу разом з агентством Creatix. Звертайтеся зараз і дозвольте нам допомогти вам сформувати майбутнє вашого бренду. Давайте разом досягнемо вражаючих довготривалих результатів!
					</Text>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						Понеділок-п'ятниця (10:00-18:00), субота (10:00-18:00).
					</Text>
				</Box>
				<LinkBox
					flex-direction="row"
					padding="15px 25px 15px 25px"
					border-width="2px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(247, 251, 255, 0.12)"
					sm-padding="10px 15px 10px 15px"
					href="tel:0506416624"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="24px"
						color="--light"
						margin="0px 8px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="--light">
						0506416624
					</Text>
				</LinkBox>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
				md-margin="0px 0px 50px 0px"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
						border-color="--color-light"
					>
						Адреса
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						Square of Defenders of Ukraine, Kharkiv, Kharkiv Oblast, 61000
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Контакти
					</Text>
					<Link
						href="tel:0506416624"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						text-align="center"
						margin="0px 0px 15px 0px"
						hover-color="#f5eceb"
					>
						0506416624
					</Link>
					<Link
						href="mailto:info@yourdomain.com"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						margin="0px 0px 15px 0px"
						text-align="center"
						hover-color="#f5eceb"
					>
						info@fluxenon.com
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Години роботи
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Понеділок-п'ятниця (9:00-21:00), субота (10:00-21:00).
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footernew>
			<Override slot="text2">
				Square of Defenders of Ukraine, Kharkiv, Kharkiv Oblast, 61000
			</Override>
			<Override slot="link5" />
			<Override slot="link4" />
		</Components.Footernew>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde651926d910020ed4648"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});